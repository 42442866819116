// reactstrap components
import { Container, Col, Row, Button } from 'reactstrap';

import React from 'react';
import ParticleBackground from 'components/Particles';

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <div
      style={{
        background: '#00000 !important',
      }}
    >
      <div
        className="section"
        style={{
          background: '#000000',
          backgroundImage: `url(${require(`assets/images/bg-6.png`)})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: size ? '100%' : 'cover',
          backgroundAttachment: size ? 'fixed' : 'scroll',
          backgroundSize: 'cover',
          paddingTop: '8rem',
          overflow: 'hidden',
        }}
      >
        {/* <div className="overlay-primary"></div> */}

        <Container style={{ marginTop: !size && '-1rem' }}>
          <Row className="justify-content-center">
            <Col md={7} xs={12} className="text-center">
              <img
                src={require(`assets/images/logo1.png`)}
                style={{ maxWidth: '450px' }}
                width="100%"
                alt="dt22"
              />
              <img
                src={require(`assets/images/logo/housing.jpg`)}
                style={{
                  maxWidth: '300px',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
                alt="dt22"
                className="mt-3"
              />
              <img
                src={require(`assets/images/logo/PHOTO-2022-05-15-15-54-07 (1).png`)}
                style={{
                  maxWidth: '100%',
                  maxHeight: '115px',
                  objectFit: 'contain',
                }}
                alt="dt22"
                className="mt-3 ml-3"
              />

              <h3 className="text-primary text-300">
                ROYAL MAXIM PALACE KEMPINSKI CAIRO, EGYPT
                <br />
                MAY 24-25 2022
              </h3>

              <div className="container-fluid row mt-4">
                <Col lg={12} className="px-0 text-center">
                  <Button
                    href="/register"
                    className="rounded-0 navbar-item-custom text-400 text-white px-4"
                    color="primary"
                    size="lg"
                    target="_blank"
                    style={{ background: '#cdbc55' }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: '#fff',
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: 700,
                      }}
                    >
                      ENQUIRE NOW
                    </span>
                  </Button>{' '}
                  <Button
                    href="/request-agenda"
                    className="rounded-0 navbar-item-custom text-400 text-white px-4"
                    color="primary"
                    size="lg"
                    target="_blank"
                    style={{ background: '#cdbc55' }}
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        color: '#fff',
                        textTransform: 'none',
                        fontSize: '20px',
                        fontWeight: 700,
                      }}
                    >
                      REQUEST AGENDA
                    </span>
                  </Button>
                </Col>
              </div>
            </Col>

            {/* <Col md={6} xs={12} className="mt-4"></Col> */}
          </Row>
        </Container>
        {/* <div
          className="triangle-container"
          style={{ position: 'absolute', top: '100px', left: '30px' }}
        >
          <div class="triangle-box">
            <i></i>
          </div>
        </div>
        <div
          className="triangle-container"
          style={{ position: 'absolute', top: '300px', left: '30px' }}
        >
          <div class="triangle-box">
            <i></i>
          </div>
        </div>
        <div
          className="triangle-container"
          style={{ position: 'absolute', top: '500px', left: '30px' }}
        >
          <div class="triangle-box">
            <i></i>
          </div>
        </div> */}
        <ParticleBackground />
      </div>
    </div>
  );
}

export default LandingPageHeader;
