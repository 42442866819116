import React from "react";
import { Row, Container, Col } from "reactstrap";
import { FaLinkedinIn } from "react-icons/fa";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white ">
      <Container fluid>
        <Row>
          <Col lg={1} xs={0} />
          <Col lg={4} style={{ paddingTop: "3rem" }}>
            <p>Organised By</p>
            <a href="/">
              <img
                src={require("assets/images/logo/3.png")}
                alt="vibe"
                style={{ maxWidth: "150px" }}
              />
            </a>
          </Col>
          <Col lg={3} className={"text-left"} style={{ padding: "3rem" }}>
            <p>
              <a href="/" style={{ color: "#333333" }}>
                Home
              </a>
            </p>
            <p>
              <a href="/#about" style={{ color: "#333333" }}>
                About
              </a>
            </p>
            <p>
              <a href="/#agenda" style={{ color: "#333333" }}>
                Agenda
              </a>
            </p>

            <p>
              <a
                href="https://saifee-events.com/privacy-policy"
                style={{ color: "#333333" }}
              >
                Privacy-Policy Saifee-Events
              </a>
            </p>
          </Col>
          <Col lg={4} style={{ padding: "3rem" }}>
            <p>Connect With Us</p>

            {social.map((s) => (
              <a
                key={s.name}
                className={` text-900 px-2`}
                style={{
                  color: "#02092c",
                  fontSize: "30px",
                }}
                href={s.link}
              >
                <FaLinkedinIn />
              </a>
            ))}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center pt-10"}>
            <p>© 2021 Saifee-Events. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  // {
  //   name: "facebook",
  //   link: "",
  // },
  // { name: "twitter", link: "" },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/saifee/?viewAsMember=true",
  },
  // {
  //   name: "instagram",
  //   link: "",
  // },
];
