import React from 'react';
import { Button, Container, Row, Col, Input, Form, Alert, Spinner, Label, Card } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import countries from './countries';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import CardImg from 'reactstrap/lib/CardImg';
import { FaEnvelope, FaMobile, FaPhone } from 'react-icons/fa';
import { Redirect } from 'react-router-dom';

function Register({ type }) {
  const [details, setDetails] = React.useState({
    type,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const checkboxChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.checked });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }
    if (!details.policy) {
      setLoading(false);
      setError('Please accept our privacy policy');
      return null;
    }
    if (!details.country) {
      setLoading(false);
      setError('Please select a country from the dropdown');
      return null;
    }
    fetch('https://us-central1-edc2022-64d59.cloudfunctions.net/api/email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        // setSuccess(
        //   'Thank you. We have received your request, our team will get back to you shortly.'
        // );
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError('Something went wrong please try again');
      });
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: '#000',
        }}
      >
        <Container className="my-auto text-white text-400 p-2">
          <Row className="justify-content-center">
            {type === 'REGISTER' && (
              <Col lg={5}>
                {content.map((c, i) => (
                  <Card className="mb-0 text-white" key={c.name}>
                    <Container fluid>
                      <Row
                        style={{
                          backgroundColor: c.color,
                          borderRadius: 'none',
                          borderBottom: !(c.length - 1 === i) && '1px solid #fff',
                        }}
                        className="py-5"
                      >
                        <Col xs={8}>
                          <h3 className="text-400 m-0">{c.name}</h3>
                        </Col>
                        <Col
                          xs={4}
                          className="text-center"
                          style={{
                            alignItems: 'center',
                            flexWrap: size ? 'nowrap' : 'wrap',
                          }}
                        >
                          <h3 className="text-700 m-0">
                            <b>{c.price}</b>
                          </h3>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                ))}
              </Col>
            )}

            <Col lg={7}>
              {type === 'REGISTER' && <p></p>}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center text-white">
                  <Col lg={3} className="my-2">
                    <label>First Name*</label>
                    <Input
                      placeholder="First Name"
                      type="text"
                      name="first_name"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={3} className="my-2">
                    <label>ِLast Name*</label>
                    <Input
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Office Email*</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Phone*</label>
                    <PhoneInput
                      country={'eg'}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="w-100"
                      dropdownClass="text-dark"
                      value={details.phone}
                      onChange={(phone) => {
                        setDetails({ ...details, phone });
                      }}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Job Title*</label>
                    <Input
                      placeholder="Job Title"
                      type="text"
                      name="job"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Company*</label>
                    <Input
                      placeholder="Company"
                      type="text"
                      name="company"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Country *</label>
                    <Typeahead
                      onChange={(country) => {
                        setDetails({ ...details, country: country[0] });
                      }}
                      placeholder="Country"
                      options={countries}
                    />
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>What profile you belong to? *</label>
                    <Input type="select" name="profile" onChange={onChangeDetails} required>
                      <option value="">Select</option>
                      <option>Project owner/Developer </option>
                      <option>Contractor</option>
                      <option>Sun contractor</option>
                      <option>Architecture </option>
                      <option>Engineering</option>
                      <option>Consultant</option>
                      <option>Facility Management</option>
                      <option>Software/Tech provider</option>
                    </Input>
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Are you looking to implement BIM in your project? *</label>
                    <Input type="select" name="implement" onChange={onChangeDetails} required>
                      <option value="">Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </Input>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>What technology in Construction are you interested in? *</label>
                    <Input type="select" name="technology" onChange={onChangeDetails} required>
                      <option value="">Select</option>
                      <option>Data democratization</option>
                      <option>Geospatial data/GIS</option>
                      <option>BIM</option>
                      <option>Smart cities</option>
                      <option>Transportation</option>
                      <option>Asset management</option>
                      <option>Cost Management</option>
                    </Input>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Which BIM software/company are you using? *</label>
                    <Input
                      placeholder="Type Here"
                      type="text"
                      name="software"
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Promo Code</label>
                    <Input
                      placeholder="Promo Code"
                      type="text"
                      name="promo"
                      onChange={onChangeDetails}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>No. of Delegates </label>
                    <Input type="select" name="delegateCount" onChange={onChangeDetails}>
                      <option value="">Select</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>other</option>
                    </Input>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Select Pricing Option </label>
                    <Input type="select" name="paymentMode" onChange={onChangeDetails}>
                      <option value="">Select</option>
                      <option>Industry Delegate Pass - USD 149</option>
                      <option>Group of 3 and more - USD 110</option>
                      <option>Vendor Delegate - USD 500</option>
                    </Input>
                  </Col>

                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input type="checkbox" name="news" onChange={checkboxChange} /> I would like
                        to opt-in to receive Industry News, Trends, Events, and Offers from Saifee
                        Events. You can unsubscribe at any time.
                      </Label>
                    </div>
                  </Col>
                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input type="checkbox" name="policy" onChange={checkboxChange} />
                        Protecting your privacy is important to Saifee Events. We collect data
                        directly from you when you will Register for this event. The information we
                        procure includes your name, organization details, email and phone number
                        which is strictly kept confidential with us and will only be shared{' '}
                        <b>with the Sponsors of the Egypt Digital Construction 2022</b> event, to
                        help them communicate with you their relevant offerings. By clicking submit,
                        I acknowledge the Saifee Events{' '}
                        <a
                          href="http://www.saifee-events.com/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: '#cdbc55' }}
                          className="text-400"
                        >
                          <b>Privacy </b>
                        </a>
                        Statement
                      </Label>
                    </div>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    block
                    className="btn text-white text-700 mt-3"
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={loading}
                    style={{ background: '#cdbc55' }}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
        <CreateAlert success={success} error={error} />
      </div>
      <div
        className="section border-top border-bottom"
        style={{
          background: '#fff',
          alignItems: 'center',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="m-0 my-4 text-700 text-primary text-uppercase text-center">
                CONTACT US
              </h1>
            </Col>

            <Col lg={4} xs={6} className="py-2">
              <Card className="p-3 text-center">
                <img
                  src={require('assets/images/logo/3.png')}
                  width="70%"
                  alt="saifee"
                  className="mx-auto"
                />
                <h5 className="text-700 pt-3">Saifee Events Pvt. Ltd.</h5>
                <p className=" pt-3">
                  Pune, MH,
                  <br /> India
                </p>
                <p>
                  <a href={`mailto:huzefa@saifee-events.com`}>
                    <FaEnvelope /> huzefa@saifee-events.com
                  </a>
                </p>
                <p>
                  <FaPhone /> +91 992 328 5610
                </p>

                <hr />
                <h5 className="text-700 pt-3">Osama Reda</h5>
                <p className=" pt-3">
                  <a href={`mailto:huzefa@saifee-events.com`}>
                    <FaEnvelope /> osama@saifee-events.com
                  </a>
                </p>
                <p>
                  <FaPhone /> +20 106 701 1122
                </p>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    name: 'Industry Delegate Pass',
    price: 'USD 149',
    color: '#d9ca7f',
  },
  {
    name: 'Group of 3 and more',
    price: 'USD 110',
    color: '#cbba4c',
  },
  {
    name: 'Vendor Delegate',
    price: 'USD 500',
    color: '#000',
  },
];

function CreateAlert({ success, error }) {
  const color = success ? 'success' : 'danger';
  const message = success ? success : error;
  if (!success && !error) {
    return '';
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: '1rem' }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;
