import React from 'react';

import { Container, Row, Col, Card } from 'reactstrap';
import CardTitle from 'reactstrap/lib/CardTitle';

function Sponsor() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const rows = [
    ['BIM PARTNER'],
    ['GOLD SPONSOR'],
    ['SILVER SPONSOR'],
    ['EXHIBITOR'],
    ['VISITING PARTNER'],
    ['SUPPORTING ASSOCIATION'],
    ['LOCAL PARTNER'],
  ];
  return (
    <>
      <div
        style={{
          backgroundColor: '#000',
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1 className="m-0 my-4 text-300 text-white text-center">
            <span className=" text-700">SPONSORS & </span>
            <br />
            PARTNERS
          </h1>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s, i) => (
                  <Col lg={s.lg || 4} className={'mx-auto'} key={i}>
                    <a href={s.link}>
                      <h3 className="text-center text-700 text-dark bg-primary">{s.type}</h3>
                      <Card style={{ borderRadius: 0, maxWidth: '350px' }} className="mx-auto">
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          className="mx-auto"
                          alt={s.name}
                          width="100%"
                          style={{ borderRadius: 0 }}
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: 'archdesk',
    pageLink: 'archdesk',
    type: 'GOLD SPONSOR',
    image: 'archdesk.png',
    text: [''],
    link: 'https://archdesk.com/',
    lg: 6,
  },
  {
    name: 'Mtwo',
    pageLink: 'Mtwo',
    type: 'BIM PARTNER',
    image: 'mtwo.png',
    text: [''],
    link: 'https://www.softwareone.com/ar-ae/industries/mtwo-construction-cloud',
    lg: 6,
  },

  {
    name: 'cadmasters',
    pageLink: 'cadmasters',
    type: 'LOCAL PARTNER',
    image: 'cadmasters.jpg',
    text: [''],
    link: 'http://cadmasters.org/',
    lg: 6,
  },
  {
    name: 'Lenovo',
    pageLink: 'Lenovo',
    type: 'SILVER SPONSOR',
    image: 'lenovo.png',
    text: [''],
    link: 'https://www.lenovo.com/eg/en/',
    lg: 6,
  },
  {
    name: 'Trimble',
    pageLink: 'Trimble',
    type: 'SILVER SPONSOR',
    image: 'Trimble.png',
    text: [''],
    link: 'https://www.trimble.com/en',
    lg: 6,
  },
 
  {
    name: 'syteq',
    pageLink: 'syteq',
    type: 'EXHIBITOR',
    image: 'syteq.jpg',
    text: [''],
    link: 'https://syteq.net/',
    lg: 6,
  },
  {
    name: 'IGH',
    pageLink: 'IGH',
    type: 'EXHIBITOR',
    image: 'IGH Logo.png',
    text: [''],
    link: 'https://www.igh.hr/en/',
    lg: 6,
  },
  {
    name: 'Geomatex-Bentley',
    pageLink: 'Geomatex-Bentley',
    type: 'EXHIBITOR',
    image: 'Geomatex-Bentley (1).jpg',
    text: [''],
    link: 'https://www.geomatex.com/',
    lg: 6,
  },
  {
    name: 'Kaitech',
    pageLink: 'Kaitech',
    type: 'VISITING PARTNER',
    image: 'kaitech.jpg',
    text: [''],
    link: 'https://gokaitech.com/',
    lg: 6,
  },
  {
    name: 'CIOB',
    pageLink: 'CIOB',
    type: 'SUPPORTING ASSOCIATION',
    image: 'CIOB.png',
    text: [''],
    link: 'https://www.ciob.org/',
    lg: 6,
  },
  {
    name: 'BIM AFRICA',
    pageLink: 'BIM AFRICA',
    type: 'SUPPORTING ASSOCIATION',
    image: 'bimafrica.jpg',
    text: [''],
    link: 'https://bimafrica.org/',
    lg: 6,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy('type');
export const sponsors = groupByType(sponsorsP);
