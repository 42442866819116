import React from 'react';
import Navbar from 'components/Navbar.js';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.js';

import Footer from 'components/Footer.js';
import Sponsor from 'components/Sponsor.js';

import { sponsors } from './content';
import { Container, Row, Col } from 'reactstrap';

function ThankYou({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU `} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0, background: '#fff' }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center text-dark p-5 m-5">
              <h3>
                {id === 'REGISTER' && (
                  <>
                    Thank you for your interest to attend the EGYPT DIGITAL CONSTRUCTION 2022. We
                    will contact you soon for further registration process.
                  </>
                )}
                {id === 'AGENDA' && (
                  <>
                    Thank you for your interest in the EGYPT DIGITAL CONSTRUCTION 2022. We
                    will be sending a copy of the aegnda shortly.
                  </>
                )}
                {id === 'REPORT' && (
                  <>
                    Thank you for your interest in the EGYPT DIGITAL CONSTRUCTION 2022. We
                    will be sending a copy of the report shortly.
                  </>
                )}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default ThankYou;
