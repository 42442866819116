import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div
      className='section'
      style={{
        background: "#ffffff",
        backgroundImage: `url(${require(`assets/images/bg5.png`)})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: 'top right'
      }}
    >
      <div className='pb-5' data-parallax={true}>
        <Container className={""}>
          <Row className='justify-content-start'>
            <Col md='10'>
              <h1 className='m-0 my-4 text-300 text-primary'>
                <span className='text-700'>WHY</span> ATTEND
              </h1>
            </Col>
            {whyContent.map((c) => (
              <Col lg={c.grid} xs={11} className='px-1'>
                <Card className='rounded-0' style={{ background: c.color }}>
                  <div className='d-flex p-3 align-items-center'>
                    <div className='px-2'>
                      <img
                        src={require(`assets/newicons/${c.image}`)}
                        style={{ maxWidth: "80px" }}
                        width='100%'
                        alt='about'
                        // className="mx-auto"
                      />
                    </div>
                    <p className='text-400 py-3 text-white'>{c.title}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className='pb-5' data-parallax={true}>
        <Container className={""}>
          <Row className='justify-content-start'>
            <Col md='11'>
              <h1 className='m-0 my-4 text-300 text-primary'>
                <span className='text-'>WHO CAN</span> ATTEND
              </h1>
            </Col>
            <Col lg={11}>
              <Row>
                {whoContent.map((c) => (
                  <Col lg={4} xs={11} className='px-1'>
                    <Card
                      className='rounded-0'
                      style={{ background: c.color, height: "100px" }}
                    >
                      <div className='d-flex p-3'>
                        <div className='pl-2 pr-3 align-self-center'>
                          <img
                            src={require(`assets/newicons/${c.image}`)}
                            style={{ maxWidth: "40px" }}
                            width='100%'
                            alt='about'
                            // className="mx-auto"
                          />
                        </div>
                        <p className='text-400 py-3 text-white align-self-center'>
                          {c.title}
                        </p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='pb-5' data-parallax={true}>
        <Container className={""}>
          <Row className='justify-content-start'>
            <Col md='11'>
              <h1 className='m-0 my-4 text-300 text-primary'>
                <span className='text-700'>WHO SHOULD</span> SPONSOR
              </h1>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={4}>
                  <Card className='rounded-0' style={{ background: "#282a2a" }}>
                    <div className='d-flex p-3'>
                      <div className='pl-2 pr-3 align-self-center'>
                        <img
                          src={require(`assets/newicons/13.png`)}
                          style={{ maxWidth: "40px" }}
                          width='100%'
                          alt='about'
                          // className="mx-auto"
                        />
                      </div>
                      <p className='text-300 text-white align-self-center'>
                        SOFTWARE PROVIDERS FOR:
                      </p>
                    </div>
                  </Card>
                  {softwareFor.map((s) => (
                    <Card
                      className='rounded-0 pl-3 py-2 d-flex'
                      style={{ background: "#282a2a" }}
                    >
                      <p className='text-400 text-white text-left'>{s.title}</p>
                    </Card>
                  ))}
                </Col>
                <Col lg={8}>
                  <Row>
                    {sponsorContent.map((c) => (
                      <Col lg={c.grid} xs={11} className='px-1'>
                        <Card
                          className='rounded-0'
                          style={{ background: c.color, height: "100px" }}
                        >
                          <div className='d-flex p-2 align-items-center'>
                            <div className='px-2'>
                              <img
                                src={require(`assets/newicons/${c.image}`)}
                                style={{ maxWidth: "80px" }}
                                width='100%'
                                alt='about'
                                // className="mx-auto"
                              />
                            </div>
                            <p className='text-400 py-3 text-white'>
                              {c.title}
                            </p>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const whoContent = [
  { title: "GOVERNMENT", image: "8.png", color: "#282a2a" },
  { title: "CONSULTANTS", image: "9.png", color: "#cdbc55" },
  { title: "CONTRACTORS", image: "10.png", color: "#282a2a" },
  { title: "REAL ESTATE DEVELOPERS", image: "6.png", color: "#cdbc55" },

  { title: "ENGINEERING", image: "12.png", color: "#282a2a" },
  { title: "ARCHITECTS", image: "3.png", color: "#cdbc55" },
  { title: "FACILITY MANAGERS", image: "1.png", color: "#282a2a" },
];

export default Attend;

const whyContent = [
  {
    title: "Explore new technologies across the construction life-cycle",
    image: "13.png",
    grid: 5,
    color: "#282a2a",
  },
  {
    title:
      "Gain real time insights via aec-industry best practices and case studies",
    image: "7.png",
    grid: 5,
    color: "#cdbc55",
  },
  {
    title:
      "Understand what systems and methodology works best for you and your firm",
    image: "5.png",
    grid: 8,
    color: "#282a2a",
  },
];

const sponsorContent = [
  {
    title: "GIS Surveying Systems And Tools ",
    image: "2.png",
    grid: 6,
    color: "#cdbc55",
  },
  {
    title: "BIM & Cad Service Providers",
    image: "10.png",
    grid: 6,
    color: "#282a2a",
  },
  {
    title: "Drones",
    image: "11.png",
    grid: 6,
    color: "#282a2a",
  },

  {
    title: "AEC Worstations, Scanners And Printers",
    image: "4.png",
    grid: 6,
    color: "#cdbc55",
  },
  {
    title: "Building Automation And Management System Providers",
    image: "1.png",
    grid: 12,
    color: "#282a2a",
  },
];

const softwareFor = [
  { title: "Architecture Designs" },
  { title: "Cost Management" },
  { title: "MEP & Civil Engineering" },
  { title: "Construction Project Management" },
  { title: "Facility Management" },
];

{
  /* <Row className='justify-content-center'>
            <Col lg={3} className='text-center px-1 mt-5'>
              <Button
                href='/register'
                className='rounded-0 text-400 text-white'
                color='primary'
                size='lg'
                rel='noopener noreferrer'
                target='_blank'
                style={{ background: "#20bdbe" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  ENQUIRE NOW
                </span>
              </Button>
            </Col>
          </Row> */
}
