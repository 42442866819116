import React from "react";
import { Container, Row, Col } from "reactstrap";

function SpeakerModal({ speaker }) {
  if (speaker && speaker.image) {
    return (
      <>
        <Container>
          <div className='owner' style={{ paddingTop: "10rem" }}>
            <div className='avatar'>
              <img
                alt={speaker.name}
                className='img-rounded img-no-padding img-responsive'
                src={require(`assets/speakers/${speaker.image}`)}
              />
            </div>
            <div className='name'>
              <p className='text-700 text-uppercase mt-2 mb-0 d-block px-0'>
                {speaker.name}
              </p>
              <p
                className='text-300 m-0'
                dangerouslySetInnerHTML={{ __html: speaker.title }}
              />
              <p className='text-400 m-0 d-block px-0'>{speaker.company}</p>
            </div>
          </div>
          <Row>
            <Col className=' mt-3' md='12'>
              {speaker.text.map((t, index) => (
                <p
                  key={t}
                  style={{ fontSize: "14px" }}
                  dangerouslySetInnerHTML={{ __html: t }}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </>
    );
  } else return "";
}

export default SpeakerModal;
