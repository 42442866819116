import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import Modal from "reactstrap/lib/Modal";
import SpeakerModal from "./SpeakerModal";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  function SpeakerCard({ speaker }) {
    return (
      <Container>
        <Row>
          <Col lg={4} xs={12}>
            <a href='/' onClick={(e) => updateModal(e, speaker)}>
              <Card
                className='card-background-image mx-auto p-3'
                style={{
                  borderRadius: 0,
                  boxShadow: "none",
                  background: "transparent",
                }}
              >
                <Container fluid>
                  <Row>
                    <Col xs={12} className=''>
                      <div className='text-center'>
                        <h3 className='text-700 mb-2 mb-0 text-g d-block px-0'>
                          {speaker.sr}
                        </h3>
                      </div>
                    </Col>
                    <Col xs={12} className='px-0'>
                      <img
                        src={require(`assets/speakers/${speaker.image}`)}
                        alt='speaker'
                        width='100%'
                        className='rounded-circle p-2'
                        style={{ border: "2px #20bdbe dashed" }}
                      />
                    </Col>

                    <Col xs={12} className='px-0'>
                      <div className='text-center'>
                        <h4 className='text-700 mt-2 mb-0 text-g d-block px-0'>
                          {speaker.sTitle}
                        </h4>
                        <p className='text-700 text-white mt-2 mb-0 d-block px-0'>
                          {speaker.name}
                        </p>
                        <p className='text-300 text-white m-0'>
                          {speaker.title}
                        </p>
                        <p
                          className='text-400 text-white  m-0 d-block px-0'
                          // style={{ fontSize: "16px" }}
                        >
                          {speaker.company}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card>
            </a>
          </Col>
          <Col lg={2}></Col>
          <Col lg={6} xs={12} className='align-self-center'>
            {speaker.Child}
          </Col>
        </Row>
      </Container>
    );
  }

  const speakers = [
    {
      sr: "Workshop 1",
      name: "Rahul Shah",
      sTitle:
        "Digital Twin for the built environment – An interactive workshop by BSI",
      title: "Director - Built Environment",
      company: "BSI, UK",
      image: "rahul.png",
      text: [
        `Rahul Shah is a Sector Development Director at British Standards Institution (BSI). Rahul has worked with asset owners, main contractors, architects, engineers and manufacturers for over 23 years and has held leadership roles responsible for driving digital transformation, innovation, and leading on BIM strategy and implementation programmes globally. During his career Rahul has gained experience of implementing various digital initiatives on a diverse range of projects, including large scale multi-billion-pound urban regeneration schemes through to hospitals, airports, residential, commercial, data centres, mixed-use public spaces, stadiums and metros. He has gained thorough understanding of the risks and opportunities faced by today’s global construction businesses while going through digitalization.  

      In his current role at BSI Rahul drives digital transformation agenda in the built environment globally and supports clients in Digital Trust, Innovation, BIM, Digital Twin, Smart Cities, IoT, and Information Security and Resilience. `,
      ],
      Child: (
        <Container>
          <Row className='justify-content-center'>
            <Col lg={12}>
              <p className='text-400 text-white'>
                This is an interactive workshop structured around organised
                discussions and group activities to enable participants to
                define their business specific Digital Twin business case and
                implementation roadmap. You will get the most out of this
                workshop by being an active participant, asking questions and
                engaging in discussions and group activities.
              </p>
              <h3 className='text-g'>Worskshop Leaders</h3>
            </Col>
            {content.map((c) => (
              <Col xs={11}>
                <a href='/' onClick={(e) => updateModal(e, c)}>
                  <Card
                    className='rounded-0 incomplete-box'
                    style={{
                      background: "transparent",
                      // border: "5px #20bdbe solid",
                    }}
                  >
                    <div className='container-fluid row ml-1 py-2'>
                      <Col xs={2} className='align-self-center'>
                        <img
                          src={require(`assets/speakers/${c.image}`)}
                          style={{ minWidth: "80px", marginLeft: "-50px" }}
                          width='100%'
                          alt='about'
                          className='rounded-circle'
                        />
                      </Col>
                      <Col xs={10} className='align-self-center'>
                        <p
                          className='text-400 mt-2 text-white'
                          dangerouslySetInnerHTML={{ __html: c.title }}
                        ></p>
                      </Col>
                    </div>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      ),
    },

    {
      sr: "Workshop 2",
      sTitle:
        "Levels of Engagement: Pathways from Digital Twins into the Metaverse",
      name: "Paul Doherty",
      title: "President & CEO",
      company: "The Digit Group Inc., USA",
      image: "paul.jpg",
      text: [
        `Paul is President and CEO of The Digit Group, inc. (TDG), a Singapore-registered Smart City real estate development and investment company.  As seen on Bloomberg TV, acknowledged by CNBC as one of America's Business Titans and reported by Forbes as “Changing the World”, Paul is an award-winning architect. a Senior Fellow of the Design Futures Council and a Fellow of the International Facility Management Association (IFMA).  Concurrently, Paul is the co-founder and producer of the critically acclaimed AEC Hackathon (www.aechackathon.com).  Paul’s current work includes the design, planning, construction, and operations of Smart Cities around the world.`,
      ],
      Child: (
        <Container>
          <Row>
            <Col xs={11}>
              <p className='text-400 text-white'>
                Join us for a Masterclass exploration of the pathways from
                digital twins to the Metaverse. This workshop will focus on how
                the design and construction industry develops the digital DNA of
                the physical built environment and how this stewardship is
                leading to a massive reimagining of how the digital DNA can be
                repurposed to lay the foundation of virtual worlds in the
                Metaverse. Attending this Masterclass will provide you with
                understanding the terms, identifying the tools and processes,
                and how to value the digital assets our industry creates into
                new forms of revenues. Real world examples will be presented to
                assist in defining your own pathways as you move from digital
                twin into the Metaverse.
              </p>
            </Col>
          </Row>
        </Container>
      ),
    },
    {
      sr: "Workshop 3",
      sTitle:
        "Digital Twins, defining the vision and roadmap for successful implementation",
      name: "Mike Turpin",
      title: "Technology Director",
      company: "Priestland Consulting",
      image: "Mike Turpin.jpg",
      text: [
        `  Mike is a BIM and Digital Engineering specialist, helping
      organisations to make the most of their investment in new ways
      of working, whilst improving their efficiency and gaining value
      from digital technologies. Developing his initial career as a
      Civil Engineer (Institute of Civil Engineers) working in
      structural and public health design, Mike then moved into roles
      supporting companies adopting BIM for the first time in the UK
      and overseas. Mike also works in the International Team at CDBB
      (Centre for Digital Built Britain) where he provides technical
      support to help public sector organisations leverage value from
      Digital. `,
      ],
      Child: (
        <Container>
          <Row>
            <Col xs={11}>
              <p className='text-400 text-white'>
                Key Takeaways
                <br />
                <ul>
                  <li>
                    Understanding the key business related objectives for
                    Digital Twin implementation
                  </li>
                  <li>
                    A consideration of the multiple pillars required for
                    technologically driven change
                  </li>
                  <li>
                    How to define a suitable vision for a Digital Twin which is
                    achievable and linked to business goals
                  </li>
                  <li>
                    The creation of a bespoke roadmap for your own Digital Twin
                    implementation journey.
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Container>
      ),
    },
  ];
  return (
    <>
      <div
        style={{
          // backgroundImage: "url(" + require("assets/images/bg.jpg") + ")",
          background: "#051936",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className='section section-with-space'
        data-parallax={true}
      >
        <div className='filter' />
        <Container className={""}>
          <Row className='justify-content-md-center'>
            <Col md='12'>
              <h1
                className=' text-center text-g mb-5'
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                WORKSHOPS
              </h1>
            </Col>
            {speakers.map((speaker, index) => (
              <Col className='mx-auto px-0' lg={12} xs={12} key={index}>
                <SpeakerCard speaker={speaker} />
              </Col>
            ))}
          </Row>
          <Row className='justify-content-center'>
            <Col lg={12} className='text-center px-1 mt-5'>
              <Button
                href='/workshop'
                className='rounded-0 text-400 text-white'
                color='primary'
                size='lg'
                rel='noopener noreferrer'
                target='_blank'
                style={{ background: "#20bdbe" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  To know more & Register
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className='section profile-content'>
            <button
              aria-label='Close'
              className='close'
              data-dismiss='modal'
              type='button'
              onClick={(e) => updateModal(e)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#888888",
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

const content = [
  {
    title:
      "<b>David Glennon</b>, Director of Digital Delivery, <b>The Red Sea Development Co, Saudi Arabia</b>",
    image: "David Glennon.jpg",
    text: [
      `David Glennon is the Senior Digital Delivery Director for The Red Sea Development Company (TRSDC) , a closed joint stock company wholly owned by the Public Investment Fund (PIF), Saudi Arabia’s sovereign wealth fund. He oversees the development and implementation of new and innovative digital delivery techniques for a new international luxury tourism destination set along the Red Sea as part of the Kingdom’s Vision 2030. 

Bringing more than 25 years’ experience in the construction industry, he oversees the use of pioneering technology to improve the design and construction process, introducing automation and data driven insights to deliver fast-track construction, design for manufacture and assembly, and enabling the Smart Destination strategy through a ‘Digital Twin’ to enhance customer experience. 

Previous positions include Managing Director of DoubleD.io, a digital accelerator for the built environment, and Head of Digital for Arcadis in the UK, leading their digital programme and the delivery of platforms, innovation, data and analytics and ecosystem thinking. As Director of Digital Project Delivery at AECOM, he was responsible for creating and implementing digital strategy across Europe, the Middle East, India and Africa. Prior to this, David held senior positions at Mott MacDonald and Lend Lease. 
      `,
    ],
  },
  {
    title:
      "<b>Wajdi Mereb</b>, Senior Manager BIM – Development Design Dept.,<b> MIRAL, UAE</b>",
    image: "Wajdi Mereb (1).jpeg",
    text: [
      `Currently, Wajdi is managing the Digital Twin Initiative & BIM implementation in multi-billion’s projects in one of the most reputable governmental entities in UAE- Miral Asset Management- that has a clear strategy for digital transformation targeting to become a fully  integrated data-driven asset owner. Hence, paving the way to translate 4th Industrial Revolution into reality & integrated with its ecosystem to deliver smart & agile solutions for Leisure & Entertainment industry in Yas Island & beyond.  

      Previously, he initiated suites of smart & intelligent objectives that had been translated into a portfolio of projects in Dubai RTA, which became a regional benchmarking reference that led to a market culture change as far as digital transformation phenomena is concerned, in addition he led RTA to be a key partner in developing BSI’s fundamental BIM Certification schemes “ISO 19650 & UK BIM Level 2”, together with obtaining their certificates as the 1st organization worldwide. Also he managed a successful BIM implementation in multi-billion strategic infrastructure projects “e.g. Metro Route 2020 project- $ 3 billion value” that contributed directly to winning the EXPO 2020 file for UAE. 
      
      `,
    ],
  },
  {
    title:
      " <b>Andy Boutle</b>, Head of Digital Construction,<b> ALEC , UAE</b>",
    image: "andy.png",
    text: [
      `With over twenty years industry experience, Andy has worked in various engineering and management roles for both consultants and contractors with a more recent specialization in Building Information Modeling (BIM). Known as a vocal proponent for digital transformation across the construction industry, Andy's latest role as Head of Digital Construction has meant a broader focus on ALEC's digitization with an emphasis on increasing workflow communication and vertical efficiencies.

      As the former Head of BIM - Construction Division, Kier Group, Andy's experience enabled him to take a leading role in driving positive industry change, while co-authoring several strategies including "Digital by Default", the Kier Group's Digital Vision and Strategy and Kier's Information Management Function Assignment Matrix, Exchange Information Requirements (EIR) Template and Master Information Delivery Plan (MIDP).
      
      During the same period, Andy also served as the Programme Lead for Kier's ISO 19650 migration as well as being responsible for scoping and maintaining a consistent reporting mechanism for BIM implementation and performance across all construction business units. Under his supervision, Kier Group achieved its Kitemark certification in July 2019, covering construction, infrastructure, highways and design + business services. `,
    ],
  },
  {
    title:
      "<b>Amr Saad</b>, Head of Digital Engineering, Information Management,<b> BIM, ROSHN, Saudi Arabia</b>",
    image: "amr.jpg",
    text: [
      `Amr leads the strategic implementation of digital engineering information management functions for ROSHN Real Estate portfolio. Amr provides insights to decision-makers in the early stages to ensure delivery is aligned with organisational requirements within time and budget and serves the end-users. He had extensive exposure to different delivery phases and practical BIM implementation experience. International bodies appreciate Amr's experience; the Royal Institute of chartered surveyors (RICS) recognised him as a Chartered BIM manager. Additionally, awarded the Expert Elite rank by Autodesk and Project Information Manager / Task Information Manager by the BRE.Amr has a record of accomplishment in transforming international firms from the traditional approaches to the BIM-Data-driven process, enhancing the awareness about digital transformation through conducting workshops, presentations, and training programs to a broad spectrum of audiencesF 
      `,
    ],
  },
];

export default Attend;
