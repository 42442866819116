import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          background: `url(${require("assets/images/landscape.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className='section'
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-primary"></div>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8}>
              <h1 className='m-0 my-4 text-400 text-white'>
                <span className='text-700 text-primary'>EVENT</span>
                <br />
                SUMMARY
              </h1>
              <p className='text-400 text-white'>
                Following the success of Saifee Events’{" "}
                <span className='text-primary text-700'>
                  DIGITAL CONSTRUCTION
                </span>{" "}
                conference series in Kenya and Nigeria, Africa’s leading event
                dedicated to Construction Technologies is proud to foray into
                Egypt as a physical event.
                <br />
                <br />
                As per{" "}
                <span className='text-primary text-700'>
                  Egypt Project Market Outlook 2021
                </span>
                , Egypt is the 3rd largest projects market in the MENA region
                with currently{" "}
                <span className='text-primary text-700'>$435.9 billion</span>{" "}
                worth of projects planned or under way in the country. Even with
                a slight decline due to the COVID pandemic, Egypt maintains an
                overall positive growth in construction
                <br />
                <br />
                Although BIM and construction tech is implemented in key
                projects across the MEA region, there is a need for a wider
                outreach via skill development, embedding a corporate digital
                culture and having all project teams complying to standardized
                processes for optimal construction project delivery.
                <br />
                <br />
                This 2-day conference on{" "}
                <span className='text-primary text-700'>
                MAY 24-25, 2022 in Cairo
                </span>{" "}
                is aimed to push for better BIM implementation, showcase new
                Tech innovations and share industry best practices and case
                studies via eminent industry speakers and global vendorsز
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
