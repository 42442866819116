import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className='section py-5'
        style={{
          backgroundColor: "#000",
          backgroundImage: `url(${require(`assets/images/${
            size ? "bg4.png" : "bg5.png"
          }`)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: size ? "cover" : "100%",
          backgroundPosition: "top right",
        }}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col className='ml-auto mr-auto text-dark' md={12}>
              <h1 className='m-0 my-4 text-300 text-white'>
                <span className=' text-700 text-primary'> AGENDA </span>
                <br />
                KEY TOPICS
              </h1>
            </Col>

            {content.map((c) => (
              <Col xs={11} lg={4} className='pr-0'>
                <div className='container-fluid row justify-content-center col-lg-11 col-xs-12 pr-0'>
                  <Card
                    className='rounded-0 text-center'
                    style={{
                      background: "transparent",
                      // border: "5px #20bdbe solid",
                    }}
                  >
                    <img
                      src={require(`assets/icons/${c.image}`)}
                      style={{
                        maxWidth: "80px",
                      }}
                      width='100%'
                      alt='about'
                      className='mx-auto'
                    />
                    <p
                      className='text-400 mt-2 text-white'
                      dangerouslySetInnerHTML={{ __html: c.title }}
                    />
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

const content = [
  {
    title: `<span class="text-primary text-700">
    Leadership Panel:
</span>
<br /> Formulating a sound digital 
roadmap for an agile & 
profitable construction 
business`,
    image: "14.png",
  },
  {
    title: `
    <span class="text-primary text-700">
    Leadership Panel:
</span>
<br /> Formulating a sound digital 
roadmap for an agile & 
profitable construction 
business
  `,
    image: "22.png",
  },
  {
    title: ` <span class="text-primary text-700">Democratization of 
  Digital Twins via Data </span><br/>
  – value of Data and 
  Business decisions 
  across organizational 
  functions.`,
    image: "19.png",
  },
  {
    title: `
  <span class="text-primary text-700">Enhancing collaboration 
  and reducing gaps</span><br/> between AEC project teams 
  via key technologies
  `,
    image: "15.png",
  },
  {
    title: `
  <span class="text-primary text-700">Exploring Digital Twins</span><br/>to drive Smart Cities in Egypt
  `,
    image: "22.png",
  },
  {
    title: `
  <span class="text-primary text-700">Recognizing Data</span><br/> as the core for effective BIM 
  implementation
  `,
    image: "19.png",
  },
  {
    title: `
  <span class="text-primary text-700">City Information
  Modeling (CIM) –</span><br/>developments in Egypt
  `,
    image: "26.png",
  },
  {
    title: `
  <span class="text-primary text-700">GIS</span><br/>innovations for smart 
  surveying
  `,
    image: "18.png",
  },
  {
    title: `
  <span class="text-primary  text-700">Innovations for smart 
  building management</span>
  `,
    image: "26.png",
  },
  {
    title: `
  <span class="text-primary text-700">Enterprise-wide Skill 
  development</span><br/>for successful BIM 
  management 
  `,
    image: "21.png",
  },
  {
    title: `
  <span class="text-primary  text-700">AI & ML
  </span><br/> in Construction
  `,
    image: "17.8bceaab3.png",
  },
  {
    title: `
  <span class="text-primary  text-700">Industry-lead case studies 
  and Proof of Concepts
 
  </span><br/> of Tech inclusion
  `,
    image: "12.png",
  },
];
