import React from 'react';

import { Container, Row, Col, Card, Modal, Button } from 'reactstrap';
import SpeakerModal from 'components/SpeakerModal.js';
import Slider from 'react-slick';
import ShowMoreText from 'react-show-more-text';

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'p-10',
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: 'none',
            background: 'transparent',
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  alt="speaker"
                  width="100%"
                  className="rounded-circle p-2"
                  style={{ border: '2px #cdbc55 dashed' }}
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center">
                  <h4 className="text-700 mt-2 mb-0 text-dark d-block px-0 bg-primary">
                    {speaker.sTitle}
                  </h4>
                  <p className="text-700 mt-2 mb-0 d-block text-primary px-0">{speaker.name}</p>
                  <p
                    className="text-400 m-0"
                    style={{ fontSize: '13px' }}
                    dangerouslySetInnerHTML={{ __html: speaker.title }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
          backgroundImage: `url(${require(`assets/images/bg5.png`)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundPosition: 'top right',
        }}
        className="section py-5"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1 className="m-0 my-4 text-300 text-dark text-center">
                <span className=" text-700">SPEAKERS & </span>
                <br />
                PANELISTS
              </h1>
            </Col>
          </Row>

          <Row className="py-4">
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={3} xs={12} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={'mx-auto'}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1">
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: '#fff',
                    textTransform: 'none',
                    fontWeight: 700,
                    fontSize: '20px',
                  }}
                >
                  ENQUIRE NOW
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                color: '#888888',
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

export const speakers = [
  {
    name: 'MAJOR GENERAL KHALED ABDEL A’AL',
    sTitle: 'OPENING KEYNOTE SPEAKER',
    title: `<b>GOVERNOR OF CAIRO, EGYPT</b>`,
    image: 'KHALED.jpg',
    text: [``],
  },
  {
    name: 'MOHAMMAD SAFAAN',
    sTitle: 'KEYNOTE SPEAKER',
    title: `MINISTER, <b>MANPOWER AND IMMIGRATION, EGYPT</b>`,
    image: 'Mohamed Safaan2.jpg',
    text: [``],
  },
  {
    name: 'ENG. TAREK AL-NABARAWY',
    sTitle: 'KEYNOTE SPEAKER',
    title: `CAPTAIN OF ENGINEERS, <b>EGYPTIAN SYNDICATE OF ENGINEERS, EGYPT</b>`,
    image: 'Tarek Nabarawy.jpg',
    text: [``],
  },
  {
    name: 'ISSAM EL-ABSI',
    sTitle: 'CHAIRMAN | DAY 1',
    title: `CEO, <b>AIDIGITS 
    LTD, EGYPT</b> | 
    DIRECTOR – BIM FOR 
    INFRATRUCTURE 
    MASTERS PROGRAM 
    –  <b>ZIGURAT GLOBAL 
    INSTITUTE OF 
    TECHNOLOGY, SPAIN</b>`,

    image: 'Essam_Elabasi_Photo_01.jpg',
    text: [``],
  },

  {
    name: 'PAULA GRACE ',
    sTitle: 'CHAIRMAN | DAY 2',
    title: `CO-PARTNER, HEAD OF DIGITAL ENGINEERING AND BIM IMPLEMENTATION | 
    <b>MPAEC, EGYPT</b>`,

    image: 'Paula Grace.jpg',
    text: [``],
  },
  {
    name: 'WALEED ABDELFATTAH',

    title: `SENIOR VICE 
    PRESIDENT & 
    REGIONAL MANAGER AFRICA | 
    <b>HILL 
    INTERNATIONAL, 
    EGYPT</b>`,

    image: 'Waleed Abdelfattah.png',
    text: [``],
  },
  {
    name: `AHMED ASSEM 
    AHMED RAGHEB`,

    title: `DEPUTY COO FOR 
    TECHNICAL AFFAIRS 
    AND TECHNOLOGY 
    APPLICATIONS | 
    
    <b>REDCON 
    CONSTRUCTION CO., 
    EGYPT</b>`,

    image: 'Ahmed Assem.png',
    text: [``],
  },
  {
    name: `MAGED AL 
    HAWARY`,

    title: `GROUP CHIEF 
    INFORMATION 
    OFFICER | 
    
    <b>ASGC, UAE</b>`,

    image: 'MagedAlHawary (1).jpg',
    text: [``],
  },
  // {
  //   name: `AHMED
  //   HENDY`,

  //   title: `CHIEF PROJECTS
  //   OFFICER |

  //   <b>SAUDI-EGYPTIAN
  //   DEVELOPERS, EGYPT</b>`,

  //   image: "Ahmed Hendy.jpg",
  //   text: [``],
  // },
  {
    name: `TAMER HAMDY 
    EL-SAYED`,

    title: `GROUP HEAD OF IT
    | 
    <b>MASTER BUILDER GROUP, EGYPT</b>`,

    image: 'tamer.jpg',
    text: [``],
  },
  {
    name: `MOHAMMED ABDELRAHMAN`,

    title: `ASSOCIATE BIM DIRECTOR
    | 
    <b>THE RED SEA DEVELOPMENT CO., SAUDI ARABIA</b>`,

    image: 'Mohammed Abdelrahman (1).jpg',
    text: [``],
  },

  {
    name: `AREF 
    ABDELRAOUF`,

    title: `GENERAL MANAGER 
    – DEVELOPMENT & 
    DESIGN
    | 
    
    <b>AL-FUTTAIM GROUP 
    REAL ESTATE, EGYPT</b>`,

    image: 'Aref Abdelraouf.jpg',
    text: [``],
  },
  // {
  //   name: `NANCY NAGY`,

  //   title: `DIRECTOR OF
  //   DEVELOPMENT

  //   |

  //   <b> EMAAR MISR, EGYPT</b>`,

  //   image: "Nancy Nagy.jpg",
  //   text: [``],
  // },
  // {
  //   name: `AHMED
  //   GOMAA`,

  //   title: `GENERAL MANAGER
  //   ATA-ABDULMOHSIN
  //   |
  //   <b>ALTHEYAB &
  //   PARTNERS, EGYPT</b>`,

  //   image: 'Ahmed Gomaa.png',
  //   text: [``],
  // },
  {
    name: `SHERIF AHMED`,

    title: `CHIEF- FACILITIES OPERATIONS, PROJECTS, EHS & REALESTATE IN HEALTHCARE | 
    ADVISOR TO THE 
    BOARD
    | 
    <b>SIAC FACILITIES 
    MANAGEMENT, EGYPT</b>`,

    image: 'Sherif Ahmed.png',
    text: [``],
  },
  {
    name: `SHAHENDA SHOKRY`,

    title: `PROJECT CONTROLS 
    MANAGER & HEAD OF 
    BIM IMPLEMENTATION
    | 
    <b>ORASCOM 
    CONSTRUCTION, 
    EGYPT</b>`,

    image: 'Shahenda Shokry.png',
    text: [``],
  },

  {
    name: `MAGED 
    ZAGOW PH.D.`,

    title: `DIRECTOR OF 
    ARCHITECTURAL 
    DESIGN AND DIGITAL 
    ARCHITECTURE 
    PROGRAM
    | 
    <b>GALALA UNIVERSITY
    PRINCIPLE – UNIT 
    ARCHITECTURE & 
    PLANNING, EGYPT</b>`,

    image: 'Maged Zagow.png',
    text: [``],
  },
  {
    name: `MOHAMED 
    HEMDAN`,

    title: `VDC HEAD
    
    | 
    <b>KABBANI 
    CONSTRUCTION 
    GROUP, EGYPT</b>`,

    image: 'MOHAMED HEMDAN_EDC.png',
    text: [``],
  },
  {
    name: `DR. AHMED 
    EHAB`,

    title: `ASSISTANT 
    PROFESSOR – BIM 
    & CONSTRUCTION 
    MANAGEMENT
    | 
    <b>BADR UNIVERSITY IN CAIRO, 
    EGYPT,</b>`,

    image: 'Ahmed Dehab.jpg',
    text: [``],
  },
  {
    name: `RANIA ABDEL 
    FATTAH`,

    title: `HEAD OF BIM 
    DEPARTMENT & 
    PROJECT DIGITAL 
    DELIVERY MANAGER,
   
    | 
    <b> COSMOS-E 
    ENGINEERS & 
    CONSULTANTS, 
    EGYPT</b>`,

    image: 'Rania A.Fattah.jpg',
    text: [``],
  },
  {
    name: `SARAH SAFWAT`,

    title: `BIM MANAGER-ARCHITECTURE UNIT
    | 
    <b>  E.C.G.S.A., EGYPT</b>`,

    image: 'Sara Safwat.jpeg',
    text: [``],
  },
  {
    name: `ASMAA A. ATTA`,

    title: `ASMAA A. ATTA
    CORPORATE BIM MANAGER
    | 
    <b> SAUDI DIYAR 
    CONSULTANTS, 
    EGYPT</b>`,

    image: 'Asmaa Atta.png',
    text: [``],
  },
  {
    name: `NOHIER EL-SAMNY`,

    title: `
    DEPUTY ENGINEERING MANAGER
    | 
    <b> SQUARE 
    ENGINEERING FIRM, 
    EGYPT</b>`,

    image: 'Nohier El-Samny.jpg',
    text: [``],
  },
  {
    name: `MOHAMMED 
    FAWZY`,

    title: `
    BIM MANAGER
    | 
    <b>SHAKER 
    CONSULTANCY, 
    EGYPT</b>`,

    image: 'Mohamed Fawzy.png',
    text: [``],
  },
  {
    name: `MOHAMED 
    MONTASSER`,

    title: `BIM MANAGER/
    TECHNICAL AFFAIRS
    | 
    <b>TALAAT MOUSTAFA 
    GROUP, EGYPT</b>`,

    image: 'Mohamed Montasser.jpg',
    text: [``],
  },
  {
    name: `FATHY GAD`,

    title: `BIM MANAGER & COMPUTATIONAL DESIGN SPECIALIST
    | 
    <b>DAR, EGYPT</b>`,

    image: 'Fathy Gad.jpg',
    text: [``],
  },
  {
    name: `AYMAN OKAIL`,

    title: `
    GLOBAL ENGINEERING 
PROCESS MANAGER & 
BIM REFERENT
    | 
    <b>COLAS RAIL, EGYPT</b>`,

    image: 'Ayman Okail Photo (1).jpg',
    text: [``],
  },
  // {
  //   name: `DR. SALAH OMAR OMRAN`,

  //   title: `
  //   SENIOR PROJECTS BIM MANAGER
  //   |
  //   <b>TURNER,
  //   KUWAIT</b>`,

  //   image: 'Salah Omran.jpg',
  //   text: [``],
  // },
//   {
//     name: `NOURAN 
//     BADAWI`,

//     title: `
//     HEAD OF BIM 
// ARCHITECTURAL 
// DEPT. & BIM PROJECT 
// MANAGER
//     | 
//     <b>CONSOLIDATED 
//     CONTRACTORS 
//     INTERNATIONAL 
//     COMPANY, EGYPT
//     </b>`,

//     image: 'Nouran Badawi.jpg',
//     text: [``],
//   },
  {
    name: `MOSTAFA ELASHMAWY`,
    title: `SENIOR BIM AND GIS MANAGER | <b>WSP, UAE</b>`,
    image: 'Mostafa alashmawy.jpeg',
    text: [``],
  },
  {
    name: `EDWARD HARTUNG`,
    title: `VP SALES, | <b> ARCHDESK, UAE</b>`,
    image: 'Edward Hartung.jpeg',
    text: [``],
  },
  {
    name: `JAMES FRAMPTON`,
    title: `MIDDLE EAST DIRECTOR, SOFTWAREONE | <b> MTWO CLOUD, UAE</b>`,
    image: 'James Frampton_DT.jpeg',
    text: [``],
  },
  {
    name: `OMAR HABIB`,
    title: `SENIOR PROJECT CONTROL SPECIALIST | <b>MADKOUR GROUP, EGYPT</b>`,
    image: 'Omar Habib.jpeg',
    text: [``],
  },
  {
    name: `MOHAMED ESSAM`,
    title: `EXECUTIVE DIRECTOR – EGYPT | <b>ELTIZAM, EGYPT</b>`,
    image: 'Mohamed Essam.jpg',
    text: [``],
  },
  {
    name: `ARCH DEENA AL MAHDY`,
    title: `LECTURER- DEPARTMENT OF ARCHITECTURAL ENGINEERING | <b>THE BRITISH UNIVERSITY IN EGYPT, EGYPT</b>`,
    image: 'Deena 11.jpg',
    text: [``],
  },
  {
    name: `AHMED ABOUALFA`,
    title: `HEAD OF DIGITAL DELIVERY AND BI | <b>DIRIYAH GATE DEVELOPMENT AUTHORITY, SAUDI ARABIA</b>`,
    image: 'Ahmed Abou Alfa (1).jpg',
    text: [``],
  },
  {
    name: `MOHAMMAD ALAGOUZ`,
    title: `COMMITTEE COORDINATOR | <b>BIM AFRICA, EGYPT</b>`,
    image: 'AlAgouz.jpg',
    text: [``],
  },
  {
    name: `IBRAHIM ELMELIGY`,
    title: `ARCHITECTURAL SECTION HEAD-CAPITAL MEDICAL HEALTHCARE CITY PROJECT | <b>CAIRO FOR INVESTMENT & REAL ESTATE DEVELOPMENT, EGYPT</b>`,
    image: 'Ibrahim Elmeligy.jpeg',
    text: [``],
  },
  {
    name: `HANY AHMED`,
    title: `BUSINESS DEVELOPMENT MANAGER | <b>TRIMBLE</b>`,
    image: 'Hany Salah.jpeg',
    text: [``],
  },
  {
    name: `TAREQ MASRI`,
    title: `DIRECTOR OF SALES & CHANNEL | <b>TRIMBLE</b>`,
    image: 'Tareq Masri.jpeg',
    text: [``],
  },

  {
    name: 'ENG. SEIF NASSRAT',
    title: 'TECHNICAL COORDINATION SECTOR DIRECTOR | <b>SQUARE ENGINEERING FIRM, EGYPT</b>',
    image: 'Seif Nassrat.JPG',
    text: [``],
  },

  {
    name: 'EZZAT SABRY',
    title: 'MANAGING DIRECTOR | <b>GEOMATEX, EGYPT</b>',
    image: 'Ezzat Sabry.jpg',
    text: [``],
  },

  {
    name: 'HOSSAM KHALED',
    title: 'BIM CONSULTANT | <b>SYTEQ, EGYPT</b>',
    image: 'Hossam Khaled.jpeg',
    text: [``],
  },
  {
    name: 'MOSTAFA EMAD SAID',
    title:
      'CO-FOUNDER | DIGITAL ENGINEERING DIRECTOR | <b>KAITECH ENGINEERING & TECHNOLOGY, EGYPT</b>',
    image: 'Mostafa Emad.jpg',
    text: [``],
  },
  {
    name: 'ABDELMONEM MORSY',
    title: 'COUNTRY SALES MANAGER COMMERCIAL SECTOR | <b>LENOVO</b>',
    image: 'Monem_Lenovo.jpg',
    text: [``],
  },
  {
    name: 'GHADA SADEK',
    title: 'BIM MANAGER | <b>AL KIFAH CONTRACTING, EGYPT</b>',
    image: 'Ghada Sadek.jpeg',
    text: [``],
  },
  {
    name: 'MOHAMED FADEL',
    title: 'BIM MANAGER AT SITE | <b>ENGINEERING CONSULTANTS GROUP S.A., EGYPT</b>',
    image: 'Mohamed Fadel.jpg',
    text: [``],
  },
  {
    name: 'WALID ABDELAZIZ',
    title: 'BUSINESS DEVELOPMENT EXECUTIVE, SOFTWAREONE | <b>MTWO CONSTRUCTION CLOUD, UAE</b>',
    image: 'walid Photo (1).jpg',
    text: [``],
  },
  {
    name: 'MAJD ABUDAGGA',
    title: 'LEAD CONSULTANT, SOFTWAREONE | <b>MTWO CONSTRUCTION CLOUD, UAE</b>',
    image: 'Majd.jpg',
    text: [``],
  },
];
